// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from '../components/table-of-contents'
import '../components/table-of-contents/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import BookSchema from '../components/schema/book-schema'
import ProductSchema from '../components/schema/product-schema'

import website from '../seo/website.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract:
    "In this book Aikya shares her approach and the glimpses of wonder she encountered in facilitating the World Game with children in Auroville. World Game, also known as Sandplay, is an educational and creative invitation to express one's individuality and uniqueness, in an atmosphere of openness, joy of discovery and trust in the future. The book is authored by Aikya and the web book is created by Prisma Books.",
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/i7.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="home-page" pageSchema={pageSchema} {...props}>
    <BookSchema
      data={{
        name: 'Glimpses of Wonder by Aikya',
        description:
          "In this book Aikya shares her approach and the glimpses of wonder she encountered in facilitating the World Game with children in Auroville. World Game, also known as Sandplay, is an educational and creative invitation to express one's individuality and uniqueness, in an atmosphere of openness, joy of discovery and trust in the future. The book is authored by Aikya and the web book is created by Prisma Books.",
      }}
    />
    <ProductSchema
      data={{
        name: 'Glimpses of Wonder by Aikya',
        description:
          "In this book Aikya shares her approach and the glimpses of wonder she encountered in facilitating the World Game with children in Auroville. World Game, also known as Sandplay, is an educational and creative invitation to express one's individuality and uniqueness, in an atmosphere of openness, joy of discovery and trust in the future. The book is authored by Aikya and the web book is created by Prisma Books.",
      }}
    />
    <div className="container cover-page" style={{ marginBottom: '2.48rem' }}>
      <GatsbyImage className="as-paragraph" image={getImage(props.data.img1)} />
      <h1>{website.name}</h1>
      <p style={{ marginBottom: '1.24rem' }}>
        In this book Aikya shares her approach and the glimpses of wonder she
        encountered in facilitating the World Game with children in Auroville.
        World Game, also known as Sandplay, is an educational and creative
        invitation to express one's individuality and uniqueness, in an
        atmosphere of openness, joy of discovery and trust in the future.{' '}
        <Link to="/about">Read more →</Link>
      </p>
      <TableOfContents />
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
